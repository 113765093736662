.slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.slider-track {
    background: transparent;
}

.slider-thumb {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border: 2px solid white;
}

.slider-thumb-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: white;
    font-size: 20px;
}

.slider-thumb-inner span {
    flex: 1;
    text-align: center;
}