.galery{
    display:flex;
    justify-content: center;
    margin-left: 20px;
    margin-top: 20px;
}

.items{
    margin-bottom: 1rem;
}

.card {
    border-radius: 11px;
    margin-bottom: 1rem;
}