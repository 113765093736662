.container{
    display: flex;
    justify-content: center;
    width: 100%;
    text-align:center;
    box-sizing: border-box;
    position: relative;
}

.content{
    position: absolute;
    display: flex;
    z-index: 9;
    padding: 0px;
    width: 100%;
}

.mainContent{
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.photo{
    position: relative;        
    vertical-align: center;
    width:100%;
    height: 2200px;
}